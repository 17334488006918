import React, { useState, useContext, useEffect } from "react"
import RichTextRenderer from "./DynamicRichText"
import { ActivityModalContext } from "../context/ActivityModalContext"
import Link from "../components/Link"
import {
  Modal,
  Section,
  Loader,
  theme,
  Card,
  Box,
  GridContainer,
} from "@singita/components"
import GridItem from "../components/GridItem"
import ImageWrapper from "../modules/ImageWrapper"
import { getEntries } from "../common/contentful"
import { useLanguage } from "gatsby-singita-theme/src/context/LanguageContext"

const ActivityModal = ({ title, activities, otherActivitiesTitle }) => {
  const {
    modal: { isOpen, contentId },
    toggleModal,
  } = useContext(ActivityModalContext)
  const [currentId, setCurrentId] = useState(contentId)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const lang = useLanguage()

  useEffect(() => {
    if (currentId) {
      async function getActivity() {
        setLoading(true)
        document.getElementById("modal-scrollable-content").scrollTop = 0
        const res = await getEntries({
          include: 10,
          content_type: "activity",
          "sys.id": currentId,
          locale: lang,
        })
        if (res) {
          setData(res[0])
        }
        setLoading(false)
      }
      getActivity()
    }
  }, [currentId])

  useEffect(() => {
    setCurrentId(contentId)
  }, [contentId])

  let links
  if (data?.links) {
    links = data.links.map(link => {
      return <Link {...link} />
    })
  }

  const styles = {
    sectionContainer: {
      bg: "midGrey",
      borderBottomWidth: "3px",
      borderColor: "lightGrey",
      borderStyle: "solid",
      borderWidth: 0,
      gridGap: `${theme.space[4]}px`,
      gridTemplateColumns: [
        "repeat(2, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
        "repeat(4, 1fr)",
      ],
      width: "100%",
    },
    sectionItem: {},
  }
  return (
    <Modal
      isOpen={isOpen}
      size="large"
      title={title}
      handleClose={toggleModal}
      bg={theme.colors.brandBeige}
    >
      {!data || loading ? (
        <Loader size={300} isActive hasMinHeight />
      ) : (
        <>
          <Section
            headline={data.headline}
            direction="row"
            textContainerSx={{ pr: [0, 8], top: "70px" }}
            renderCta={links}
          >
            <RichTextRenderer node={data.content} />
          </Section>
          <Section direction="column" headline={otherActivitiesTitle}>
            <GridContainer sx={{ ...styles.sectionContainer }}>
              {activities
                .filter(({ contentful_id }) => contentful_id !== currentId)
                .map((activity, index) => {
                  return (
                    <GridItem
                      key={activity.contentful_id}
                      index={index}
                      columns={[2, 2, 3, 4]}
                    >
                      <Box
                        key={activity.id}
                        sx={{ ...styles.sectionItem, cursor: "pointer" }}
                        onClick={() => setCurrentId(activity.contentful_id)}
                      >
                        <Card
                          variant="small"
                          headline={activity.headline}
                          isLink={true}
                          image={<ImageWrapper image={activity.images} />}
                        />
                      </Box>
                    </GridItem>
                  )
                })}
            </GridContainer>
          </Section>
        </>
      )}
    </Modal>
  )
}

export default ActivityModal
