import { createContext } from "react"

export const ActivityModalContext = createContext({
  modal: {
    isOpen: false,
    contentId: "",
    contentType: "",
  },
  toggleModal: () => {},
})
