import * as React from "react"
import { Button, Gallery } from "@singita/components"
import MediaWrapper from "../../modules/MediaWrapper"
import { formatImages } from "../../common/format"
import { useResourceSets } from "gatsby-singita-theme/src/context/ResourceSetContext"
import { LocaleLink } from "gatsby-singita-theme/src/components/LocaleLink"
import { useLanguage } from "gatsby-singita-theme/src/context/LanguageContext"

const LodgeGallery = ({ lodge, patternProps }) => {
  const assets = React.useMemo(() => formatImages(lodge.galleries), [lodge])

  const lang = useLanguage()
  const lodgesRS = useResourceSets("lodges", lang)

  if (!assets.length) return null

  return (
    <Gallery
      variant="carousel"
      items={assets}
      showPattern={false}
      patternProps={patternProps}
      hasPagination={true}
      renderCta={() => (
        <LocaleLink
          to={`/lodge/${lodge.slug}/gallery`}
          state={{
            isModal: true,
          }}
        >
          <Button
            variant="outline"
            size="small"
            icon="grid"
            iconPosition="left"
            colorScheme={lodge.color}
          >
            {lodgesRS.gallery.buttonText}
            {/* <Translation>{t => t("gallery.more")}</Translation> */}
          </Button>
        </LocaleLink>
      )}
    >
      {assets.map((item, idx) => (
        <MediaWrapper key={idx} {...item} />
      ))}
    </Gallery>
  )
}

export default LodgeGallery
