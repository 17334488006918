import {
  ActionBar,
  Box,
  Button,
  Card,
  Container,
  Flex,
  Gallery,
  GridContainer,
  List,
  ListItem,
  Popover,
  Section,
  Text,
  theme,
} from "@singita/components"
import { graphql, Link } from "gatsby"
import { TEMPLATE_TYPES } from "gatsby-singita-theme/src/common/constants"
import { hideByTag, showByTag } from "gatsby-singita-theme/src/common/utils"
import HelmetWrapper, {
  getJsonLd,
} from "gatsby-singita-theme/src/components/Helmet"
import { LocaleLink } from "gatsby-singita-theme/src/components/LocaleLink"
import Main from "gatsby-singita-theme/src/components/Main"
import AvailabilityContext from "gatsby-singita-theme/src/context/AvailabilityContext"
import { useLanguage } from "gatsby-singita-theme/src/context/LanguageContext"
import { LodgeContext } from "gatsby-singita-theme/src/context/LodgeContext"
import { useResourceSets } from "gatsby-singita-theme/src/context/ResourceSetContext"
import { useActionBar, useModal, useSeo } from "gatsby-singita-theme/src/hooks"
import React, { useCallback, useContext, useEffect } from "react"
import { CONSTANTS, CONTENT_TYPES, LODGE_DATA_MAP } from "../common/constants"
import { createSlug } from "../common/utils"
import ActivityModal from "../components/ActivityModal"
import AnimateInView from "../components/AnimateInView"
import GridItem from "../components/GridItem"
import LinkWrapper from "../components/Link"
import LodgeGallery from "../components/Lodge/LodgeGallery"
import { ActivityModalContext } from "../context/ActivityModalContext"
import BannerWrapper from "../modules/BannerWrapper"
import CardHeroWrapper from "../modules/CardHeroWrapper"
import HeroVideoWrapper from "../modules/HeroVideoWrapper"
import HeroWrapper from "../modules/HeroWrapper"
import ImageWrapper from "../modules/ImageWrapper"
import Layout from "../modules/Layout"
import LayoutAlertWrapper from "../modules/LayoutAlertWrapper"
import MediaWrapper from "../modules/MediaWrapper"

const styles = {
  sectionContainer: {
    bg: "midGrey",
    borderBottomWidth: "3px",
    borderColor: "lightGrey",
    borderStyle: "solid",
    borderWidth: 0,
    gridGap: `${theme.space[4]}px`,
    gridTemplateColumns: [
      "repeat(2, 1fr)",
      "repeat(2, 1fr)",
      "repeat(3, 1fr)",
      "repeat(4, 1fr)",
    ],
    width: "100%",
  },
  sectionItem: {},
}

function scrollIntoView(elemId) {
  const elem = document.getElementById(elemId)
  const { offsetTop } = elem
  // Accounting for navbar - can improve to make use of navbar height in context
  window.scrollTo({ behavior: "smooth", top: offsetTop - 85 })
}

const LodgeTemplate = props => {
  const {
    data: { lodge },
  } = props
  const {
    lodge: { links },
  } = CONSTANTS
  const [modal, toggleModal] = useModal()
  const modalCallback = useCallback(
    (contentId, contentType) => toggleModal(contentId, contentType),
    [toggleModal]
  )

  const { lodgeData, setLodgeData } = useContext(LodgeContext)

  const patternProps = {
    color: lodge.color,
    path: lodge.pattern?.pattern,
  }

  const [actionbarRef, isMobile] = useActionBar("lg")

  useEffect(() => {
    setLodgeData(lodge.color)
    return () => {
      setLodgeData(null, null)
    }
  }, [lodge, setLodgeData])

  const DownloadLink = ({ fieldKey, variant, children }) => (
    <LinkWrapper
      variant={variant}
      colorScheme={lodge.color}
      isDownload={true}
      to={lodge[fieldKey].file.url}
      sx={{ width: ["auto", "100%"] }}
      label={children}
      external={true}
    />
  )

  const tags = lodge.tags ? JSON.parse(lodge.tags.internal.content) : []
  const seoProps = useSeo(lodge, LODGE_DATA_MAP)
  seoProps.title = `${seoProps.sdTitle} | ${lodge.region.headline} | Singita`
  const jsonLd = getJsonLd({ ...seoProps }, TEMPLATE_TYPES.LODGE)

  const lang = useLanguage()
  const lodgesRS = useResourceSets("lodges", lang)
  const hasGallery = lodge.galleries && lodge.galleries.length > 0
  const hasLodgeMenu =
    hasGallery ||
    lodge.lodgeInformation ||
    lodge.activities ||
    lodge.brochure ||
    lodge.siteplan

  return (
    <ActivityModalContext.Provider
      value={{ modal, toggleModal: modalCallback }}
    >
    <Main {...props} hideNewsletter={lodge.hideNewsletter} codes={{ lodge: lodge.code, region: lodge.region.code }}>
      <HelmetWrapper {...seoProps} schemaJsonLd={jsonLd} />
        {lodge.hero.__typename === CONTENT_TYPES.HERO ? (
          <HeroWrapper
            {...lodge.hero}
            image={lodge.hero.images ? lodge.hero.images[0] : null}
            patternProps={patternProps}
            colorScheme={lodge.color}
          />
        ) : (
          <HeroVideoWrapper
            {...lodge.hero}
            patternProps={patternProps}
            colorScheme={lodge.color}
          />
        )}
        {
          <AvailabilityContext.Consumer>
            {({ setShowAvailability }) =>
              lang === "en-US" ? (
                <ActionBar
                  descriptionColor={"baseBlack"}
                  ref={actionbarRef}
                  headline={
                    isMobile
                      ? null
                      : lodge?.structuredData?.headline || lodge.fullTitle
                  }
                  description={
                    isMobile
                      ? null
                      : `${lodge.region.headline}, ${lodge.region.country}`
                  }
                  color={lodge.color}
                  isSticky={true}
                >
                  {!isMobile && hasGallery && (
                    <>
                      <Box mr={[1]}>
                        <Link
                          to={`/lodge/${lodge.slug}/gallery/`}
                          state={{
                            isModal: true,
                          }}
                        >
                          <Button variant="outline" colorScheme={lodge.color}>
                            {lodgesRS.actionBar.photos ||
                              links.actionBar.photos}
                          </Button>
                        </Link>
                      </Box>
                    </>
                  )}
                  {!!hasLodgeMenu && (
                    <Box mr={[1]}>
                      <Button
                        variant="outline"
                        colorScheme={lodge.color}
                        renderPopover={colorScheme => (
                          <Popover>
                            {isMobile && hasGallery && (
                              <Link
                                to={`/lodge/${lodge.slug}/gallery/`}
                                state={{
                                  isModal: true,
                                }}
                              >
                                <Button
                                  variant="simple"
                                  colorScheme={colorScheme}
                                >
                                  {lodgesRS.actionBar.photos ||
                                    links.actionBar.photos}
                                </Button>
                              </Link>
                            )}
                            {!!lodge.lodgeInformation && (
                              <Button
                                variant="simple"
                                colorScheme={colorScheme}
                                onClick={() =>
                                  scrollIntoView("lodge-information-section")
                                }
                              >
                                {lodgesRS.actionBar.info ||
                                  links.actionBar.info}
                              </Button>
                            )}
                            {!!lodge.activities && (
                              <Button
                                variant="simple"
                                colorScheme={colorScheme}
                                onClick={() =>
                                  scrollIntoView("lodge-activities-section")
                                }
                              >
                                {lodgesRS.actionBar.activities ||
                                  links.actionBar.activities}
                              </Button>
                            )}
                            {lodge.brochure && (
                              <DownloadLink
                                variant="simple"
                                fieldKey="brochure"
                              >
                                {lodgesRS.actionBar.brochure ||
                                  links.actionBar.brochure}
                              </DownloadLink>
                            )}
                            {lodge.siteplan && (
                              <DownloadLink
                                variant="simple"
                                fieldKey="siteplan"
                              >
                                {lodgesRS.actionBar.siteplan ||
                                  links.actionBar.siteplan}
                              </DownloadLink>
                            )}
                            {
                              <Link to={`/promotions/`}>
                                <Button
                                  variant="simple"
                                  colorScheme={colorScheme}
                                >
                                  {lodgesRS.actionBar.promotions ||
                                    links.actionBar.promotions}
                                </Button>
                              </Link>
                            }
                          </Popover>
                        )}
                        popoverPosition="top"
                      >
                        {lodgesRS.actionBar.menu || links.actionBar.menu}
                      </Button>
                    </Box>
                  )}
                  {!!lodge.code && (
                    <Button
                      variant="primary"
                      colorScheme={lodge.color}
                      onClick={() => setShowAvailability(true)}
                    >
                      {lodgesRS.actionBar.availability ||
                        links.actionBar.availability}
                    </Button>
                  )}
                </ActionBar>
              ) : null
            }
          </AvailabilityContext.Consumer>
        }
        {!!lodge.intro && (
          <Box data-testid="lodge-intro" pt={[10, 20]}>
            <Layout
              layout={hideByTag(lodge.intro, "SPVC")}
              patternProps={patternProps}
            />
          </Box>
        )}
        {!!lodge.highlights && (
          <Box data-testid="lodge-highlights-gallery" mb={[6, 16]}>
            <Gallery
              headline={lodge.meta.highlightsTitle}
              variant="triptic"
              hasPagination
              items={lodge.highlights.map(({ caption }) => ({
                desc: caption,
              }))}
              color={lodge.color}
              headingStyles={{
                headline: {
                  px: "30px",
                },
              }}
            >
              {showByTag(lodge.highlights, "Singita").map((item, idx) => (
                <MediaWrapper
                  key={`highlight-item-${idx}`}
                  {...item}
                  style={{
                    width: "100%",
                    height: "100%",
                    userSelect: "none",
                  }}
                  imgStyle={{
                    objectFit: "cover",
                  }}
                />
              ))}
            </Gallery>
          </Box>
        )}
        {lodge.alerts &&
          lodge.alerts.map(alert =>
            lang === "en-US" ? <LayoutAlertWrapper {...alert} /> : null
          )}
        {!!lodge.activities && (
          <Container>
            <Section
              id="lodge-activities-section"
              headline={lodge.meta.activitiesTitle}
              textContainerSx={{ width: 7 / 12 }}
            >
              <GridContainer sx={{ ...styles.sectionContainer }}>
                {showByTag(lodge.activities, "Singita").map(
                  (activity, index) => {
                    return (
                      <GridItem
                        index={index}
                        columns={[2, 2, 3, 4]}
                        key={activity.id}
                      >
                        {lang === "en-US" ? (
                          <LocaleLink
                            data-testid={`lodge-activity-${index}`}
                            to={`${
                              props.pageContext.basePath
                            }/experience-${createSlug(activity.headline)}/`}
                          >
                            <Box
                              data-testid={`lodge-activity-${index}`}
                              sx={{ ...styles.sectionItem, cursor: "pointer" }}
                            >
                              <Card
                                variant="small"
                                isLink={true}
                                headline={activity.headline}
                                image={<ImageWrapper image={activity.images} />}
                              />
                            </Box>
                          </LocaleLink>
                        ) : (
                          <Box
                            data-testid={`lodge-activity-${index}`}
                            sx={{ ...styles.sectionItem, cursor: "default" }}
                          >
                            <Card
                              variant="small"
                              isLink={false} // Set to false when not linking
                              headline={activity.headline}
                              image={<ImageWrapper image={activity.images} />}
                            />
                          </Box>
                        )}
                      </GridItem>
                    )
                  }
                )}
              </GridContainer>
            </Section>
          </Container>
        )}
        {!!lodge.lodgeInformation && (
          <Container>
            <Section
              id="lodge-information-section"
              headline={lodge.meta.lodgeInfoTitle}
            >
              <List cols={2}>
                {showByTag(lodge.lodgeInformation, "Singita")
                  .filter(l => l.displayForLocale)
                  .map((info, index) => {
                    const iconId = tags[info.contentful_id]
                    return (
                      <LocaleLink
                        data-testid={`lodge-information-${index}`}
                        to={`/lodge/${lodge.slug}/information`}
                        state={{
                          isModal: true,
                          anchor: info.id,
                        }}
                      >
                        <Box key={info.id} sx={{ ...styles.sectionItem }}>
                          <ListItem
                            iconRight="chevron-right"
                            iconLeft={theme.icons[iconId]}
                            iconRightSize={12}
                            pb={[4]}
                            mt={[4]}
                            iconLeftColor={lodgeData.lodgeColor}
                            isLink={true}
                            hasBorder={true}
                          >
                            <Text size="body" fontWeight="bold">
                              {info.headline}
                            </Text>
                          </ListItem>
                        </Box>
                      </LocaleLink>
                    )
                  })}
              </List>
              <Flex flexDirection={["column", "row"]} mt={[2, 4]}>
                {lodge.siteplan && (
                  <Box mr={[0, 1]} mb={[1, 0]}>
                    <DownloadLink variant="primary" fieldKey="siteplan">
                      {lodgesRS.actionBar.siteplan || links.downloads.siteplan}
                    </DownloadLink>
                  </Box>
                )}
                {lodge.brochure && (
                  <Box mr={[0, 1]} mb={[1, 0]}>
                    <DownloadLink variant="outline" fieldKey="brochure">
                      {lodgesRS.actionBar.brochure || links.downloads.brochure}
                    </DownloadLink>
                  </Box>
                )}
                {lodge.activitiesBrochure && (
                  <Box mr={[0, 1]} mb={[1, 0]}>
                    <DownloadLink
                      variant="outline"
                      fieldKey="activitiesBrochure"
                    >
                      {lodgesRS.actionBar.activitiesBrochure ||
                        links.downloads.activitiesBrochure}
                    </DownloadLink>
                  </Box>
                )}
                {lodge.communityExperiences && (
                  <Box>
                    <DownloadLink
                      variant="outline"
                      fieldKey="communityExperiences"
                    >
                      {lodgesRS.actionBar.communityExperiences ||
                        links.downloads.communityExperiences}
                    </DownloadLink>
                  </Box>
                )}
              </Flex>
            </Section>
          </Container>
        )}
        {!!lodge.conservation &&
          hideByTag(lodge.conservation, "SPVC").map((c, index) => {
            return (
              <Box
                data-testid="lodge-conservation-partner"
                mb={[15]}
                key={index}
              >
                <CardHeroWrapper {...c} patternProps={patternProps} />
              </Box>
            )
          })}

        {!!lodge.galleries && (
          <Box data-testid="lodge-gallery-slider" mb={[15]}>
            <LodgeGallery
              lodge={lodge}
              patternProps={patternProps}
              buttonText={lodgesRS.gallery.buttonText}
            />
          </Box>
        )}

        {lodge.banners && lodge.banners.length > 0 && (
          <AnimateInView data-testid="lodge-banner" threshold={0.4}>
            <Gallery variant="banner" bg={lodge.color}>
              {showByTag(lodge.banners, "Singita").map(b => {
                const { headline, subtitle, image, content } = b
                return (
                  <BannerWrapper
                    className="BannerWrapper"
                    content={{
                      headline,
                      subtitle,
                      image,
                      content,
                    }}
                    showLogo={false}
                    showPattern={false}
                    sx={{ ...b.meta, backgroundColor: lodgeData.lodgeColor }}
                    patternProps={patternProps}
                  />
                )
              })}
            </Gallery>
          </AnimateInView>
        )}
      </Main>
      {(!!lodge.meta || !!lodge.activities) && (
        <ActivityModal
          title={lodge.meta ? lodge.meta.activitiesTitle : ""}
          activities={lodge.activities}
          otherActivitiesTitle={lodge.meta.otherActivitiesTitle}
        />
      )}
    </ActivityModalContext.Provider>
  )
}

export default LodgeTemplate

export const LODGE_QUERY = graphql`
  query lodgeQuery($slug: String!, $locale: String) {
    lodge: contentfulLodge(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      id
      color
      slug
      fullTitle
      tags {
        internal {
          content
        }
      }
      hero {
        __typename
        ... on Node {
          ... on ContentfulLayoutHero {
            ...HeroFields
          }
          ... on ContentfulLayoutHeroVideo {
            ...HeroVideoFields
          }
        }
      }
      shortDescription {
        shortDescription
      }
      region {
        headline
        country
        code
      }
      intro {
        __typename
        ...SectionLeftRightFields
      }
      conservation {
        __typename
        ...CardHeroFields
      }
      galleries {
        __typename
        ... on Node {
          ...ContentContentAssetFields
        }
      }
      highlights {
        __typename
        ... on ContentfulImage {
          tag
          ...ImageFields
          image {
            fluid(quality: 95, maxWidth: 1000, maxHeight: 665) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
      meta {
        highlightsTitle
        activitiesTitle
        lodgeInfoTitle
        otherActivitiesTitle
      }
      activities {
        id
        tag
        contentful_id
        headline
        content {
          raw
        }
        images {
          fluid(quality: 90, maxWidth: 400, maxHeight: 260) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      lodgeInformation {
        id
        tag
        contentful_id
        headline
        content {
          raw
        }
        displayForLocale
      }
      pattern {
        pattern
      }
      banners {
        ... on ContentfulContent {
          tag
          headline
          subtitle
          content {
            raw
          }
          image {
            fluid(quality: 100, maxWidth: 240) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
      ...LodgeStructuredDataFields
      siteplan {
        file {
          url
        }
      }
      brochure {
        file {
          url
        }
      }
      activitiesBrochure {
        file {
          url
        }
      }
      communityExperiences {
        file {
          url
        }
      }
      image {
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      code
      alerts {
        ...AlertFields
      }
      hideNewsletter
    }
  }
`

export const lodgeStructuredDataFields = graphql`
  fragment LodgeStructuredDataFields on ContentfulLodge {
    structuredData {
      headline
      image {
        fluid(quality: 90, maxWidth: 800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      description {
        description
      }
      altText
      meta {
        internal {
          content
        }
      }
    }
  }
`
